exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-connect-with-a-rep-js": () => import("./../../../src/pages/connect-with-a-rep.js" /* webpackChunkName: "component---src-pages-connect-with-a-rep-js" */),
  "component---src-pages-dosing-administration-js": () => import("./../../../src/pages/dosing-administration.js" /* webpackChunkName: "component---src-pages-dosing-administration-js" */),
  "component---src-pages-efficacy-js": () => import("./../../../src/pages/efficacy.js" /* webpackChunkName: "component---src-pages-efficacy-js" */),
  "component---src-pages-fibryga-for-cfd-js": () => import("./../../../src/pages/fibryga-for-cfd.js" /* webpackChunkName: "component---src-pages-fibryga-for-cfd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-safety-js": () => import("./../../../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-what-is-afd-js": () => import("./../../../src/pages/what-is-afd.js" /* webpackChunkName: "component---src-pages-what-is-afd-js" */),
  "component---src-pages-why-fibryga-js": () => import("./../../../src/pages/why-fibryga.js" /* webpackChunkName: "component---src-pages-why-fibryga-js" */)
}

