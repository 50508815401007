import React from "react";
import { AppProvider } from "@context/AppContext";
import GTM from "@utils/GTM";

import "./src/styles/fonts.css";
import "./src/styles/global.css";

export const onRouteUpdate = () => {
  GTM.reset();
};

export const wrapRootElement = ({ element }) => (
  <AppProvider>{element}</AppProvider>
);
