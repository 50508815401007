import { debounce } from "@utils/helper.js";

let distanceScrolled = -1;
let distanceThreshold = [
  { threshold: 25, value: "25%", sent: false },
  { threshold: 50, value: "50%", sent: false },
  { threshold: 75, value: "75%", sent: false },
  { threshold: 90, value: "90%", sent: false },
];

const scrollDepth = () => {
  const scrollTop =
    document.body.scrollTop || document.documentElement.scrollTop;
  let progress = Math.round(
    (scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100,
  );

  if (isNaN(progress)) progress = 0;

  if (progress > distanceScrolled) {
    distanceScrolled = progress;

    for (let i = 0; i < distanceThreshold.length; i++) {
      const status = distanceThreshold[i].sent;
      const threshold = distanceThreshold[i].threshold;
      const value = distanceThreshold[i].value;

      if (!status && progress >= threshold) {
        if ("dataLayer" in window) {
          window.dataLayer.push({
            event: "gatsbyScroll",
            gatsbyScrollDistance: value,
          });
          distanceThreshold[i].sent = true;
        }
      }
    }
  }
};

const scrollListener = debounce("scrollDepth", scrollDepth, 18);

const GTM = {
  init: () => {
    if ("dataLayer" in window && "google_tag_manager" in window) {
      scrollDepth();
      document.addEventListener("scroll", scrollListener);
    } else {
      const interval = setInterval(() => {
        if ("dataLayer" in window && "google_tag_manager" in window) {
          scrollDepth();
          document.addEventListener("scroll", scrollListener);
          clearInterval(interval);
        }
      }, 100);
    }
  },
  gatsbyScrollProgression: (value) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "gatsbyScrollProgression",
        gatsbyScrollProgressionValue: value,
      });
    }
  },
  clickableCardChange: (value) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "clickableCardChange",
        clickableCardValue: value,
      });
    }
  },
  carouselChange: (previousSlide, selectedSlide, direction, carouselName) => {
    if ("dataLayer" in window) {
      direction = document.body.clientWidth > 1024 ? "" : `_${direction}`;
      window.dataLayer.push({
        event: "carouselInteraction",
        carouselPreviousSlide: previousSlide,
        carouselSelectedSlide: selectedSlide,
        carouselDirection: direction,
        carouselName,
      });
    }
  },
  handleSectionName: (sectionName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        sectionName,
      });
    }
  },
  // form tags START
  formDelivery: (formName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "formDelivery", formName });
    }
  },
  formInitiation: (formName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "formInitiation", formName });
    }
  },
  formSubmission: (formName, completedFields) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "formCompletion", formName });

      let formDetails = "";
      // loop through completedFields and add to formDetails
      Object.keys(completedFields).forEach((key) => {
        if (["specialty", "state", "include_address", "consent"].includes(key)) {
          const fieldName = key === "include_address" ? "includeAddress" : key;
          formDetails += `${fieldName}${completedFields[key] ? `-${completedFields[key]}` : ""}_`;
        }
      });
      formDetails = formDetails.replace(/_$/, '');
      window.dataLayer.push({
        event: "formCompletionDetails",
        formName,
        formDetails,
      });
    }
  },
  formInputChange: (formName, fieldName, fieldValue) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "inputChange",
        formName,
        fieldName,
        fieldValue: fieldValue || "",
      });
    }
  },
  formInputError: (fieldName, fieldError, formName) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "inputError",
        fieldName,
        fieldError,
        formName,
      });
    }
  },
  formInputErrorFormSubmitted: (formName, fieldValue) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "formInputErrorFormSubmitted",
        formName,
        fieldValue,
      });
    }
  },
  // form tags END
  errorPage: () => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "errorPage"
      });
    }
  },
  interstitialLoad: (type) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "interstitialLoad", interstitialType: type });
    }
  },
  interstitialDismiss: (type, copy) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "interstitialDismiss", interstitialType: type, interstitialDismissCopy: copy });
    }
  },
  isiInteraction: (isExpanded) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "isiInteraction",
        isiStateChange: isExpanded ? "Collapse" : "Expand",
      });
    }
  },
  videoPlay: (videoUrl) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "videoPlay", videoUrl });
    }
  },
  videoProgressSeconds: (videoUrl, videoSeconds) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "videoProgressSeconds", videoUrl, videoSeconds });
    }
  },
  videoProgressPercentage: (videoUrl, videoPercentage) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({ event: "videoProgressPercent", videoUrl, videoPercentage });
    }
  },
  expandableContent: (event, state, expandedTitle) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "expandableContent",
        expandedContentState: state,
        expandedContentTitle: expandedTitle || event.target.textContent,
      });
    }
  },
  ctaClick: (event, ctaLocation, ctaTitle) => {
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "ctaClick", ctaLocation: (ctaLocation || (event.target.closest('footer') ? 'Footer' : 'Body')),
        ctaTitle: ctaTitle || event.target.textContent
      });
    }
  },
  anchorClick: (event, anchorTarget) => {
    const linkText = event.target.innerText || event.target.textContent;
    if ("dataLayer" in window) {
      window.dataLayer.push({
        event: "anchorClick",
        anchorTarget: anchorTarget || event.target.href,
        link_text: linkText,
      });
    }
  },
  reset: () => {
    distanceScrolled = -1;
    distanceThreshold.forEach((threshold) => (threshold.sent = false));
  },
};

export default GTM;
