import { v4 as uuidv4 } from "uuid";

export const isBrowser = typeof window !== "undefined";

export const isEDMode =
  typeof window !== "undefined"
    ? window.location.href.indexOf("ed=1") > 0
    : false;

export const getUrlParameter = (param) => {
  param = param.replace(/[\\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + param + "=([^&#]*)");
  const results = regex.exec(window.location.search);

  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const timeoutDebounce = {};
export const debounce = (name, func, wait, immediate) => {
  return function () {
    const context = this;
    const args = arguments;

    const later = () => {
      timeoutDebounce[name] = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    const callNow = immediate && !timeoutDebounce[name];

    clearTimeout(timeoutDebounce[name]);

    timeoutDebounce[name] = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
};

export const focusLoop = (e, selector) => {
  const tabPressed = e.key === "Tab" || e.keyCode === 9;

  if (!tabPressed) return;

  const elems = document.querySelectorAll(selector);

  if (elems?.length) {
    const firstElem = elems[0];
    const lastElem = elems[elems.length - 1];

    if (!Array.from(elems).includes(document.activeElement)) {
      firstElem.focus();
    }

    if (e.shiftKey) {
      if (document.activeElement === firstElem) {
        lastElem.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastElem) {
        firstElem.focus();
        e.preventDefault();
      }
    }
  }
};

export const scrollToSection = (selector) => {
  const el = document.querySelector(selector);
  if (el) {
    el.scrollIntoView({ behavior: "smooth" });
  }
};

export const handleUsercentricsStyles = () => {
  let countUC = 0;
  const intervalUC = setInterval(async () => {
    const usercentricsRoot = document.getElementById("usercentrics-root");
    if (usercentricsRoot) {
      const shadowRootUC = usercentricsRoot.shadowRoot;
      if (shadowRootUC) {
        // Fetch CSS from the file
        const response = await fetch('/cookies.css');
        const cssText = await response.text();

        // Inject styles into the shadow root
        const style = document.createElement("style");
        style.textContent = cssText;
        shadowRootUC.appendChild(style);
        clearInterval(intervalUC);
        countUC = 0;
      }
    } else {
      countUC++;

      // clearing interval after 20 seconds
      if (countUC >= 40) {
        clearInterval(intervalUC);
      }
    }
  }, 500);
};

export const extractTextFromElement = (element) => {
  if (element?.props && element?.props?.children) {
    if (typeof element.props.children === "string") {
      return element.props.children;
    } else if (Array.isArray(element.props.children)) {
      const textContentArray = element.props.children.map((child) => {
        if (typeof child === "string") {
          return child;
        } else if (typeof child === "object" && child !== null) {
          return extractTextFromElement(child);
        } else {
          return "";
        }
      });

      return textContentArray.join(" ");
    }
  } else {
    return element;
  }
};

export const getUuid = () => {
  // Check if the uuid is already in local storage
  if (typeof window !== "undefined") {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      return uuid;
    }

    // if not, generate a new uuid
    const uuid_v4 = uuidv4();
    // save the uuid to local storage
    localStorage.setItem("uuid", uuid_v4);
    return uuid_v4;
  }
};


//Copies the provided string to clipboard
export const copyToClipboard = async (string) => {
  try {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(string);
      return true;
    }
  } catch (error) {
    console.error('Failed to copy text: ', error);
    return false;
  }
};

export const setSessionCookie = (key, value) => {
  if (!isBrowser || !key) return false;

  try {
    const serializedValue = encodeURIComponent(JSON.stringify(value));
    document.cookie = `${key}=${serializedValue}; path=/; SameSite=strict`;
    return true;
  } catch (error) {
    console.error('Failed to write to cookie:', error);
    return false;
  }
};

/**
 * Gets the value of a cookie by key
 * @param {string} key - The key of the cookie to retrieve
 * @returns {any} - The parsed value of the cookie, or null if not found
 */
export const getSessionCookie = (key) => {
  if (!isBrowser || !key) return null;

  try {
    const cookies = document.cookie.split('; ');
    const cookie = cookies.find(c => c.startsWith(`${key}=`));
    if (!cookie) return null;

    const value = cookie.split('=')[1];
    try {
      return JSON.parse(decodeURIComponent(value));
    } catch {
      // If JSON parsing fails, return the raw value
      return decodeURIComponent(value);
    }
  } catch (error) {
    console.error('Failed to read from cookie:', error);
    return null;
  }
};

export const handleKeyDownDigits = (e) => {
  if (/\b[a-zA-Z]\b/.test(e.key)) {
    e.preventDefault()
  }
  if (["e", "E", "+", "-", "."].includes(e.key)) {
    e.preventDefault()
  }
}






