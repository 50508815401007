import React, { createContext, useRef, useState } from "react";
import { setSessionCookie } from "@utils/helper";
import GTM from "@utils/GTM";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const [uuid, setUuid] = useState(null);
  const headerRef = useRef(null);
  const stickyIsiRef = useRef(null);
  const footerRef = useRef(null);
  const isiRef = useRef(null);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [exitUrl, setExitUrl] = useState("");
  const [modalType, setModalType] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  const openModal = (url, modalType) => {
    setExitUrl(url);
    setShowModal(true);
    setModalType(modalType);
    GTM.interstitialLoad(modalType);
  };

  const closeModal = (e) => {
    setShowModal(false);

    if (e && e.type) {
      GTM.interstitialDismiss(modalType);
    }
    // bug fix: prevent the modal from closing before the exit URL is opened
    // without this, the exit url clears too fast, and the browser opens a tab with the same url instead of the exit one
    setTimeout(() => {
      setModalType("");
      setExitUrl("");
    }, 100);

    // set session storage if HCP modal is closed
    if (modalType === "hcp") {
      setSessionCookie("modal", true);
    }
  };

  const store = {
    uuid,
    setUuid,
    headerRef,
    stickyIsiRef,
    footerRef,
    isiRef,
    isHeaderVisible,
    setIsHeaderVisible,
    showModal,
    exitUrl,
    openModal,
    closeModal,
    modalType,
    isScrolled,
    setIsScrolled,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
